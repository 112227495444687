<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
export default {
  name: "RolePermission",
  page: {
    title: "Roles",
    meta: [
      {
        name: "Roles",
        content: appConfig.description
      }
    ]
  },
  components:{
    Multiselect,
    Layout,PageHeader
  },
  data() {
    return {
      title: "Role Permissions ",
      items: [
        {
          text: "Role Permissions",
          href: "/",
          active:true
        },
      ],

      loading: false,
      role: "",
      assignedPermissions:[],
      roleName: "",
      permissions:[],
      permission:[],
      isTableBusy: false,
      tableData: [],
      isEdit: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      pageOptions: [20, 30, 50, 100, 500, 1000, 2000, "All"],
      filter: null,
      filterOn: [],
      sortBy: "",
      sortDesc: true,
      fields: [
        {
          key: "sno",
          label:"S.No.",
          sortable: false,
          class:"align-center"
        },
        {
          key: "name",
          label:"Permission",
          sortable: true,
          class:"align-center "
        },
      ],
    }
  },
  mounted() {
    if (this.$route.params.role) {
      sessionStorage.setItem("pr", this.$route.params.role);
      sessionStorage.setItem("prn", this.$route.params.roleName);
      this.role= this.$route.params.roleName;
    }
    else if(sessionStorage.getItem("prn")){
      this.role = sessionStorage.getItem("prn");
    }
    this.getRolePermissions();
    this.getPermissions();
  },
  computed: {
    rows() {
      return this.tableData.length;
    },
  },
  methods:{
    savePermission() {
      this.submitted = true;
      this.loading = true;
      if(this.permission.length==0){
        return
      }

      let formData = new FormData();
      formData.append("role", sessionStorage.getItem('pr'));
      this.permission.forEach((item,index)=>{
        formData.append(`permissions[${index}]`,item.name);
      });

      this.axios
          .post(this.$loggedRole+'/update-role-permission', formData )
          .then((result) => {
            this.loading = false;
            this.$swal({
              icon:'success',
              text:result.data.message,
            }).then(()=>{
              this.permission=[];
             this.getRolePermissions();
            })
          })
          .catch((error) =>{
            this.$swal({
              title:'Opps!',
              icon:'error',
              text:error.response.data.message,
            })
          });

    },

   getRolePermissions(){
      this.isTableBusy=true;
    this.axios
          .get(this.$loggedRole+"/role-permissions/"+sessionStorage.getItem('pr'))
          .then((result) => {
           this.isTableBusy=false;
          this.tableData = result.data.data;
          this.permission= result.data.data;
          }).catch((error)=>{
      this.isTableBusy=false;
      this.$swal({
        title:'Opps!',
        icon:'error',
        text:error.response.data.message,
      })
          });
    },
    getPermissions(){
   this.axios
          .get(this.$loggedRole+"/permission")
          .then((result) => {
            this.permissions = result.data.data;
          }).catch((error)=>{
     this.$swal({
       title:'Opps!',
       icon:'error',
       text:error.response.data.message,
     })
          });
    }
  }
}
</script>

<template>
  <Layout>
    <PageHeader :items="items" :title="title" />
    <div class="card" style="margin-top: -55px; height:250px">
      <div class="card-body">
        <div class="inner mb-2 row">
          <div class="col-md-12">
            <h3>{{ role }} Permissions</h3>
          </div>
          <div class="col-md-12 mt-5">
            <label>Select Permission(s)</label>
            <multiselect v-model="permission" :options="permissions" :multiple="true"
                         :close-on-select="false" :clear-on-select="false" track-by="name"
                         label="name" :show-labels="false"></multiselect>
          </div>

          <div class="col-md-4 mt-1">
            <button class="btn btn-themeOrange mt-3" v-on:click="savePermission()"> Submit </button>&nbsp;
          </div>
          <!-- Filter End -->
        </div>
      </div>
    </div>
    <div class="card mt-2">
      <div class="card-body">
        <div class="col-md-12">
          <h6>Assigned Permissions:</h6>
          <br>
        </div>
        <div class="row mt-4">
          <div class="col-sm-12 col-md-4">
            <div id="tickets-table_length" class="dataTables_length">
              <label class="d-inline-flex align-items-center">
                Show &nbsp;&nbsp;
                <b-form-select style="margin-left: 5px; margin-right: 5px" v-model="perPage" size="sm" :options="pageOptions"></b-form-select>
                &nbsp;&nbsp;&nbsp; entries
              </label>
            </div>
          </div>
          <!-- Search -->
          <div class="col-sm-12 col-md-4 row">
            <div id="tickets-table_filter" class="dataTables_filter text-md-end">
              <label class="d-inline-flex align-items-center">
                Search:
                <b-form-input v-model="filter" type="search" placeholder="Search..." class="form-control form-control-sm ms-2"></b-form-input>
              </label>
            </div>
          </div>
          <!-- End search -->
        </div>
        <!-- Table -->
        <div class="table-responsive mb-0">
          <b-table
              striped
              hover
              outlined
              bordered
              :items="tableData"
              :fields="fields"
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              :busy="isTableBusy"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template v-slot:cell(sno)="data">
              <span>{{ data.index + 1 }}</span>
            </template>
            <template v-slot:cell(name)="data">
              <span class="text-success">{{ data.item.name }}</span>
            </template>
          </b-table>
        </div>
        <div class="row">
          <div class="col">
            <div class="dataTables_paginate paging_simple_numbers float-end">
              <ul class="pagination pagination-rounded mb-0">
                <!-- pagination -->
                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
              </ul>
            </div>
          </div>
        </div>

      </div>
    </div>
  </Layout>
</template>

<style scoped>

</style>